import React from "react";
import styled from "styled-components";
import { breakpoints } from "../styles/variables";
import Logo from "./Header/Logo";
import Navigation from "./Header/Navigation";
import NavToggle from "./Header/NavToggle";
import Wrapper from "./Layouting/Wrapper";

const HeaderElem = styled.header`
  height: 63px;
  width: 100vw;
  position: fixed;
  top: 0;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.29) 0px 3px 6px;
  z-index: 10;

  > div > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    position: relative;
    top: unset;
    width: 100%;
    height: unset;
    background: unset;
    box-shadow: none;
    display: flex;
    flex-direction: row;
    justify-content: center;

    > div {
      top: 33px;
      width: 160px;
      margin: 0 auto;
      position: fixed;

      > div {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
      }
    }
  }
`;

interface HeaderProps {
  navIsOpen: boolean;
  setNavIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const Header: React.FC<HeaderProps> = ({ navIsOpen, setNavIsOpen }) => (
  <HeaderElem>
    <div>
      <Wrapper mobileOnly>
        <Logo />
        <NavToggle navIsOpen={navIsOpen} setNavIsOpen={setNavIsOpen} />
      </Wrapper>
      <Navigation navIsOpen={navIsOpen} />
    </div>
  </HeaderElem>
);

export default Header;
