import React from "react";
import styled from "styled-components";
import { breakpoints, colors } from "../../styles/variables";

const ToggleDiv = styled.div`
  width: 22px;
  height: 20px;
  position: relative;
  cursor: pointer;
  z-index: 11;
  margin-top: 22px;

  > div {
    z-index: 11;
    width: 22px;
    height: 2px;
    position: absolute;
    background: #000;
    transition: transform 0.2s ease 0s, top 0.3s ease-in-out 0.3s;
  }

  > div:nth-of-type(1) {
    top: 0px;
    z-index: 12;
  }
  > div:nth-of-type(2) {
    transition: all 300ms;
    top: 9px;
  }
  > div:nth-of-type(3) {
    top: 18px;
    z-index: 12;
  }

  &.navIsOpen {
    > div {
      background: ${colors.darkerOrange};
      top: 9px;
    }
    > div:nth-of-type(2) {
      transition: all 300ms;
      background: #fff;
    }
    > div:nth-of-type(1) {
      transform: rotate(45deg);
    }

    > div:nth-of-type(3) {
      transform: rotate(-45deg);
    }

    > div {
      transition: top 0.2s ease 0s, transform 0.3s ease-in-out 0.3s;
    }
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    display: none;
  }
`;

interface NavToggleProps {
  navIsOpen: boolean;
  setNavIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const NavToggle: React.FC<NavToggleProps> = ({ setNavIsOpen, navIsOpen }) => (
  <ToggleDiv
    onClick={() => setNavIsOpen(!navIsOpen)}
    className={navIsOpen ? "navIsOpen" : ""}
  >
    <div />
    <div />
    <div />
  </ToggleDiv>
);

export default NavToggle;
