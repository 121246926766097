import React from "react";
import styled from "styled-components";
import { breakpoints } from "../../styles/variables";
import PartnerButton from "./PartnerButton";

const PartnerElem = styled.div`
  p {
    color: #fff;
    font: 14px/23px "RC Regular";
    letter-spacing: 0.7px;
    text-align: center;
    margin-bottom: 6px;

    strong {
      font-family: "RC Bold";
    }
  }

  .buttonGrid {
    width: 215px;
    display: grid;
    grid-template-columns: 100px 100px;
    grid-gap: 15px;
    margin: 0 auto;
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    margin-bottom: 0;
    margin-right: 0;

    p {
      text-align: left;
      font: 16px / 22px "RC Regular";
      letter-spacing: 0.8px;
      margin-bottom: 16px;
    }

    .buttonGrid {
      width: unset;
      display: flex;
      flex-direction: row;
      margin: 0;
    }
  }
`;

interface PartnerProps {
  partner: [
    {
      buttonLink: string;
      buttonText: string;
    }
  ];
}

const Partner = ({ partner }) => (
  <PartnerElem>
    {/* <p>
      <strong>Unsere Partner</strong>
    </p>

    <div className="buttonGrid">
      {partner.map((entry) => (
        <PartnerButton data={entry} />
      ))}
    </div> */}
  </PartnerElem>
);

export default Partner;
