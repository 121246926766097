import React from "react";
import styled from "styled-components";
import { breakpoints } from "../styles/variables";

const GridDiv = styled.div`
  @media screen AND (min-width: ${breakpoints.desktop}px) {
    display: grid;
    width: 100vw;
    padding: 0 30px;
    grid-template-columns: 1fr 1025px;
    grid-gap: 30px;
  }

  @media screen AND (min-width: ${breakpoints.desktopPlus}px) {
    display: grid;
    max-width: 1920px;
    margin: 0 auto;
    padding: 0 38px;
    grid-template-columns: minmax(160px, 1fr) 1025px minmax(0px, 1fr);

    .spacer {
      width: calc((100% - 1025px - 160px - 60px));
    }
  }
`;

const MainGrid: React.FC = ({ children }) => (
  <GridDiv>
    {children}
    <div className="spacer"></div>
  </GridDiv>
);

export default MainGrid;
