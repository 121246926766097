import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { breakpoints } from "../../styles/variables";

const LogoDiv = styled(Link)`
  display: block;
  margin-bottom: 23px;
  > img {
    width: 90px;
    display: block;
    margin: 0 auto;
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    margin-bottom: 0;

    > img {
      width: 160px;
    }
  }
`;

const Logo = () => (
  <LogoDiv to="/">
    <img src="/assets/logo/logo-white.svg" />
  </LogoDiv>
);

export default Logo;
