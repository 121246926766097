import React from "react";
import styled from "styled-components";
import { breakpoints } from "../../styles/variables";

const AddressElem = styled.address`
  font: 14px/23px "RC Regular";
  letter-spacing: 0.7px;
  text-align: center;
  color: #fff;
  margin-bottom: 23px;

  strong {
    font-family: "RC Bold";
  }

  a {
    text-decoration: none;
    color: #fff;
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    text-align: left;
    font: 16px / 22px "RC Regular";
    letter-spacing: 0.8px;
    margin-bottom: 0;
  }
`;

interface AdresseProps {
  data: {
    title: string;
    adresse: string;
    telefon: string;
    email: string;
  };
}

const Adresse: React.FC<AdresseProps> = ({ data }) => (
  <AddressElem>
    <strong>{data.title}</strong>
    <br />
    {data.adresse}
    <br />
    <a href={`tel:${data.telefon}`}>{data.telefon}</a>,{" "}
    <a href={`mailto:${data.email}`}>{data.email}</a>
    <br />
    {data.mapsLink && data.mapLinks != "" ? (
      <>
        <a href={data.mapsLink} target="_blank" rel="noreferrer">
          Google Maps
        </a>
        <br />
      </>
    ) : (
      ""
    )}
    <a
      href="https://www.instagram.com/bistrozgmbh/"
      target="_blank"
      rel="noreferrer"
    >
      <img
        width="20px"
        height="20px"
        style={{ marginTop: 10 }}
        src="/assets/gestaltungselemente/instagram.svg"
      />
    </a>
  </AddressElem>
);

export default Adresse;
