import * as React from "react";
import styled from "styled-components";

const StyledLayoutRoot = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;

  &.navIsOpen {
    overflow-y: hidden;
  }
`;

interface LayoutRootProps {
  className?: string;
}

const LayoutRoot: React.FC<LayoutRootProps> = ({ children, navIsOpen }) => (
  <>
    <StyledLayoutRoot className={navIsOpen ? "navIsOpen" : ""}>
      {children}
    </StyledLayoutRoot>
  </>
);

export default LayoutRoot;
