import React from "react";
import styled from "styled-components";
import { colors, breakpoints } from "../styles/variables";
import FooterRight from "./Footer/FooterRight";
import Logo from "./Footer/Logo";
import Adresse from "./Footer/Adresse";
import OpeningHours from "./Footer/OpeningHours";
import Partner from "./Footer/Partner";
import SubFooter from "./Footer/SubFooter";

const FooterElem = styled.footer`
  background: ${colors.orange};
  width: 100vw;

  > div {
    padding: 30px 20px 20px;
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    > div {
      display: grid;
      width: 100%;
      padding: 30px 38px;
      grid-template-columns: 1fr 1025px;
      grid-column-gap: 60px;
    }
  }

  @media screen AND (min-width: ${breakpoints.desktopPlus}px) {
    > div {
      max-width: 1920px;
      margin: 0 auto;
      grid-template-columns: minmax(160px, 1fr) 1025px minmax(0px, 1fr);
    }

    > div > div:last-of-type {
      grid-column: span 2;
    }
  }
`;

interface FooterProps {
  data: {
    telefon: string;
    email: string;
    adresse: string;
    mapsLink: string;
    offnungszeitenMatrix: [{ tage: string; zeit: string }];
    partnerMatrix: [
      {
        buttonText: string;
        buttonLink: string;
      }
    ];
  };
  footerSeiten: [
    {
      title: string;
      uri: string;
    }
  ];
}

const Footer: React.FC<FooterProps> = ({ data, footerSeiten }) => (
  <>
    <FooterElem>
      <div>
        <Logo />
        <FooterRight>
          <Adresse data={data} />
          <OpeningHours data={data} />
          <Partner partner={data.partnerMatrix} />
        </FooterRight>
      </div>
    </FooterElem>
    <SubFooter footerSeiten={footerSeiten} />
  </>
);

export default Footer;
