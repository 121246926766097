import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import { graphql, useStaticQuery } from "gatsby";

import "normalize.css";
import "../styles/fonts.css";
import "../styles/base.css";

import Header from "../components/Header";
import LayoutRoot from "../components/LayoutRoot";
import LayoutMain from "../components/LayoutMain";
import MainGrid from "../components/MainGrid";
import Footer from "../components/Footer";

interface DefaultLayoutProps {
  cms: {
    meta: {
      title: string;
      beschreibung: string;
      telefon: string;
      email: string;
      adresse: string;
      offnungszeitenMoFr: string;
      offnungszeitenSa: string;
      mapsLink: string;
      partnerMatrix: [
        {
          buttonText: string;
          buttonLink: string;
        }
      ];
    };
    footerPages: [
      {
        title: string;
        uri: string;
      }
    ];
  };
  beschreibung?: string;
  titel?: string;
}

const FlexGrow = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
`;

const DefaultLayout: React.FC<DefaultLayoutProps> = ({
  children,
  beschreibung,
  titel,
}) => {
  const data = useStaticQuery(graphql`
    query {
      cms {
        meta: entry(type: "data") {
          title
          ... on CMS_data_data_Entry {
            beschreibung
            telefon
            email
            adresse
            mapsLink
            offnungszeitenMatrix {
              ... on CMS_offnungszeitenMatrix_set_BlockType {
                tage
                zeit
              }
            }
            partnerMatrix {
              ... on CMS_partnerMatrix_partner_BlockType {
                buttonText
                buttonLink
              }
            }
          }
        }
        footerPages: entries(type: "footerSeite") {
          title
          uri
        }
      }
    }
  `);

  const [navIsOpen, setNavIsOpen] = useState(false);

  useEffect(() => {
    if (typeof document !== "undefined") {
      navIsOpen
        ? document.querySelector("html").classList.add("navIsOpen")
        : document.querySelector("html").classList.remove("navIsOpen");

      var _paq = (window._paq = window._paq || []);
      /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
      _paq.push(["setExcludedQueryParams", ["CFID", "CFTOKEN"]]);
      _paq.push(["trackPageView"]);
      _paq.push(["enableLinkTracking"]);
      (function () {
        var u = "https://stats.goeast.ch/";
        _paq.push(["setTrackerUrl", u + "matomo.php"]);
        _paq.push(["setSiteId", "183"]);
        var d = document,
          g = d.createElement("script"),
          s = d.getElementsByTagName("script")[0];
        g.async = true;
        g.src = u + "matomo.js";
        s.parentNode.insertBefore(g, s);
      })();
    }
  }, [navIsOpen]);

  return (
    <LayoutRoot navIsOpen={navIsOpen}>
      <Helmet
        title={
          titel ? titel + " – " + data.cms.meta.title : data.cms.meta.title
        }
        meta={[
          {
            name: "description",
            content: beschreibung ? beschreibung : data.cms.meta.beschreibung,
          },
        ]}
      >
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/assets/favicon/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/assets/favicon/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/assets/favicon/favicon-16x16.png"
        />
        <link rel="manifest" href="/assets/favicon/site.webmanifest" />
        <link
          rel="mask-icon"
          href="/assets/favicon/safari-pinned-tab.svg"
          color="#5bbad5"
        />
        <link rel="shortcut icon" href="/assets/favicon/favicon.ico" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta
          name="msapplication-config"
          content="/assets/favicon/browserconfig.xml"
        />
        <meta name="theme-color" content="#ffffff"></meta>
      </Helmet>
      <FlexGrow>
        <MainGrid navIsOpen={navIsOpen}>
          <Header navIsOpen={navIsOpen} setNavIsOpen={setNavIsOpen} />
          <LayoutMain>{children}</LayoutMain>
        </MainGrid>
      </FlexGrow>
      <Footer data={data.cms.meta} footerSeiten={data.cms.footerPages} />
    </LayoutRoot>
  );
};

export default DefaultLayout;
