import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { breakpoints } from "../../styles/variables";

const LogoDiv = styled(Link)`
  margin-top: 9px;
  > img {
    width: 90px;
    display: block;
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    margin-top: 0;
    margin-bottom: 55px;

    > img {
      width: 160px;
    }
  }
`;

const Logo = () => (
  <LogoDiv to="/">
    <img src="/assets/logo/logo.svg" />
  </LogoDiv>
);

export default Logo;
