import React from "react";
import styled from "styled-components";
import { breakpoints } from "../../styles/variables";

const HoursElem = styled.article`
  margin-bottom: 23px;

  table {
    margin: 0 auto;
  }
  p,
  a,
  td {
    color: #fff;
    font: 14px/23px "RC Regular";
    letter-spacing: 0.7px;
    text-align: center;
  }

  td {
    text-align: left;
  }

  strong {
    font-family: "RC Bold";
  }

  a {
    text-decoration: none;
    color: #fff;
  }

  tr td:first-of-type {
    padding-right: 15px;
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    margin-bottom: 0;

    p,
    a,
    td {
      text-align: left;
      font: 16px / 22px "RC Regular";
      letter-spacing: 0.8px;
    }

    table {
      margin: 0;
    }
  }
`;

interface OpeningHoursProps {
  data: {
    offnungszeitenMatrix: [{ tage: string; zeit: string }];
  };
}

const OpeningHours: React.FC<OpeningHoursProps> = ({ data }) => (
  <HoursElem>
    <p>
      <strong>Öffnungszeiten</strong>
    </p>
    <table>
      <tbody>
        {data.offnungszeitenMatrix.map((item) => (
          <tr>
            <td>{item.tage}</td>
            <td>{item.zeit}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </HoursElem>
);

export default OpeningHours;
