import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

import { breakpoints } from "../../styles/variables";

const SubFooterDiv = styled.div`
  background: #fff;
  padding: 6px 20px;
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  text-align: center;
  font: 14px / 30px "RC Regular";
  letter-spacing: 0.6px;

  a {
    color: black;
    text-decoration: none;
    margin: 0 12px;

    &:after {
      content: " | ";
      position: absolute;
      margin-left: 10px;
    }

    &:last-of-type {
      &:after {
        display: none;
      }
    }
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    padding: 0 30px;
    text-align: right;

    a:last-of-type {
      margin-right: 0;
    }
  }

  @media screen AND (min-width: ${breakpoints.desktopPlus}px) {
    padding: 0 38px;
  }
`;

interface SubFooterProps {
  footerSeiten: [
    {
      uri: string;
      title: string;
    }
  ];
}

const SubFooter: React.FC<SubFooterProps> = ({ footerSeiten }) => (
  <SubFooterDiv>
    {footerSeiten.map((entry) => (
      <Link to={"/" + entry.uri}>{entry.title}</Link>
    ))}
  </SubFooterDiv>
);

export default SubFooter;
