import React from "react";
import styled from "styled-components";
import { breakpoints } from "../../styles/variables";

const GroupDiv = styled.div`
  @media screen AND (min-width: ${breakpoints.desktop}px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    > * {
      margin-top: 5px;
      //margin-right: 63px;
    }
  }
`;

const FooterRight: React.FC = ({ children }) => <GroupDiv>{children}</GroupDiv>;

export default FooterRight;
