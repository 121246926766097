import React from "react";
import styled from "styled-components";
import { breakpoints, colors } from "../../styles/variables";
import NavLink from "./NavLink";

const ListElem = styled.ul`
  width: 100vw;
  text-align: center;
  padding: 30px 0 45px;
  background: ${colors.darkerOrange};
  margin-left: 0;

  &.desktop {
    display: none;
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    width: unset;
    background: unset;
    padding: 0;
    display: none;

    &.desktop {
      display: block;
    }
  }
`;

interface NavListProps {
  desktop?: boolean;
}

const NavList: React.FC<NavListProps> = ({ desktop }) =>
  desktop ? (
    <ListElem className={desktop ? "desktop" : ""}>
      <NavLink link="snacks">Angebot</NavLink>
      <NavLink link="getranke">Getränke</NavLink>
      <NavLink link="menu-bistro-z-6">Tagesmenü</NavLink>
      <NavLink link="ueber-uns">Über uns</NavLink>
      <NavLink link="kontakt#6">Kontakt</NavLink>
    </ListElem>
  ) : (
    <ListElem className={desktop ? "desktop" : ""}>
      <NavLink link="snacks">Angebot</NavLink>
      <NavLink link="getranke">Getränke</NavLink>
      <NavLink link="menu-bistro-z-6">Tagesmenü</NavLink>
      <NavLink link="ueber-uns">Über uns</NavLink>
      <NavLink link="kontakt/#6">Kontakt</NavLink>
    </ListElem>
  );

export default NavList;
