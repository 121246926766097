import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import { breakpoints, colors } from "../../styles/variables";

const LinkElem = styled.li`
  margin: 0 auto;

  a {
    letter-spacing: 2px;
    font: 20px / 60px "RC Regular";
    color: black;
    text-decoration: none;

    &:hover,
    &.active  {
      color: #fff;
    }
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    a {
      font: 20px / 50px "RC Regular";

      &:hover,
      &.active  {
        color: ${colors.orange};
      }
    }
  }
`;

interface NavLinkProps {
  link: string;
}

const NavLink: React.FC<NavLinkProps> = ({ children, link }) => (
  <LinkElem>
    <Link to={"/" + link} activeClassName="active">
      {children}
    </Link>
  </LinkElem>
);

export default NavLink;
