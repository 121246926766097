import React from "react";
import styled from "styled-components";
import { breakpoints, spacers } from "../../styles/variables";

const WrapperDiv = styled.div`
  padding: 0 ${spacers.wrapper.mobile}px;

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    padding: 0 ${spacers.wrapper.desktop}px;

    &.mobileOnly {
      padding: 0;
    }
  }

  @media screen AND (min-width: ${breakpoints.desktopPlus}px) {
    padding: 0 ${spacers.wrapper.desktopPlus}px;
  }
`;

interface WrapperProps {
  mobileOnly?: boolean;
}

const Wrapper: React.FC<WrapperProps> = ({ children, mobileOnly }) => (
  <WrapperDiv className={mobileOnly ? "mobileOnly" : ""}>{children}</WrapperDiv>
);

export default Wrapper;
