import React from "react";
import styled from "styled-components";
import { breakpoints, colors } from "../../styles/variables";
import NavList from "./NavList";

const NavElem = styled.nav`
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  background: hsla(0, 0%, 100%, 0.77);
  position: fixed;
  top: -100vh;
  left: 0;
  right: 0;
  z-index: 9;
  padding-bottom: 63px;

  &.navIsOpen {
    top: 63px;
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    top: unset;
    left: unset;
    right: unset;
    background: unset;
    width: unset;
    height: unset;
    position: relative;
  }
`;

interface NavigationProps {
  navIsOpen: boolean;
}

const Navigation: React.FC<NavigationProps> = ({ navIsOpen }) => (
  <NavElem className={navIsOpen ? "navIsOpen" : ""}>
    <NavList />
    <NavList desktop />
  </NavElem>
);

export default Navigation;
